import { Converter } from '@itm/shared-frontend/lib/utils';

import getEnvVariable from './getEnvVariable';

import {
  TargetPlatform,
  AlternativeNameType,
  PDPStatus,
  MatchingPolicyType,
  MatchingPolicyStatus,
  MatchingPolicyApprovalStatus,
  SearchMatchingStatus,
  SearchPensionValue,
  DataUploadStatusDto,
  SelectOption,
  WarningCodeEnum,
  BenefitTypeEnum,
  AmountTypeEnum,
  CalcType,
  EriTypeEnum,
  DataUploadValidationResult,
  MatchResolutionType,
  ViewDataType,
  SlaStatus,
  ReportType,
  PDPsynchronised,
  AltusSyncStatus,
  PDPRegulatorType,
  PDPRegistrationStatus,
  EnvironmentPublishStatus,
  EnvironmentPublishArea,
  AdditionalSchemeType,
  MatchingOverrideStatus,
} from 'src/types';

export const ENV_PREFIX = 'REACT_APP_' as const;

export const ENVIRONMENT = getEnvVariable('ENVIRONMENT');
export const TARGET_PLATFORM = getEnvVariable('TARGET_PLATFORM');
export const AUTH_API_URL = getEnvVariable('AUTH_API_URL');
export const ISP_API_URL = getEnvVariable('ISP_API_URL');
export const CLIENT_PORTAL_API_URL = getEnvVariable('CLIENT_PORTAL_API_URL');

export const isProductionEnv = ENVIRONMENT === 'PROD';
export const isAzureTargetPlatform = TARGET_PLATFORM === TargetPlatform.AZURE;

export const membershipAltNameTypeOptions: SelectOption[] = Converter.enumToSelectOptions(AlternativeNameType);
export const membershipPDPStatusOptions: SelectOption[] = Converter.enumToSelectOptions(PDPStatus);
export const dataUploadStatusOptions: SelectOption[] = Converter.enumToSelectOptions(DataUploadStatusDto);
export const matchingPolicyTypeOptions: SelectOption[] = Converter.enumToSelectOptions(MatchingPolicyType);
export const matchingPolicyStatusOptions: SelectOption[] = Converter.enumToSelectOptions(MatchingPolicyStatus);
export const matchingPolicyApprovalStatusOptions: SelectOption[] =
  Converter.enumToSelectOptions(MatchingPolicyApprovalStatus);
export const searchMatchingStatusOptions: SelectOption[] = Converter.enumToSelectOptions(SearchMatchingStatus);
export const searchPensionValueOptions: SelectOption[] = Converter.enumToSelectOptions(SearchPensionValue);
export const membershipPensionsWarningCodeOptions: SelectOption[] = Converter.enumToSelectOptions(WarningCodeEnum);
export const benefitTypeOptions: SelectOption[] = Converter.enumToSelectOptions(BenefitTypeEnum);
export const amountTypeOptions: SelectOption[] = Converter.enumToSelectOptions(AmountTypeEnum);
export const calcTypeOptions: SelectOption[] = Converter.enumToSelectOptions(CalcType);
export const eriTypeOptions: SelectOption[] = Converter.enumToSelectOptions(EriTypeEnum);
export const dataUploadValidationResultOptions: SelectOption[] =
  Converter.enumToSelectOptions(DataUploadValidationResult);
export const possibleMatchesResolutionTypeOptions: SelectOption[] = Converter.enumToSelectOptions(MatchResolutionType);
export const missingViewValuesViewDataTypeOptions: SelectOption[] = Converter.enumToSelectOptions(ViewDataType);
export const missingViewValuesSlaStatusOptions: SelectOption[] = Converter.enumToSelectOptions(SlaStatus);
export const pdpSynchronisedOptions: SelectOption[] = Converter.enumToSelectOptions(PDPsynchronised);
export const pdpRegulatorTypeOptions: SelectOption[] = Converter.enumToSelectOptions(PDPRegulatorType);
export const pdpRegistrationStatusOptions: SelectOption[] = Converter.enumToSelectOptions(PDPRegistrationStatus);
export const environmentPublishAreaOptions: SelectOption[] = Converter.enumToSelectOptions(EnvironmentPublishArea);

export const matchingOverrideStatusOptions: SelectOption[] = Converter.enumToSelectOptions(
  MatchingOverrideStatus,
).filter(({ value }) => value !== MatchingOverrideStatus.None);

export const membershipAuditMethodOptions = [
  'Bulk upload',
  'Manual - Personal details',
  'Manual - ERI details',
  'Manual - Accrued benefit details',
  'Manual - PDP status',
  'PDP - Matching details',
  'Manual - Matching details',
].map<SelectOption>((v) => ({ value: v, label: v }));

export const searchPDPStatusOptions = [
  { label: 'Active', value: PDPStatus.Active },
  { label: 'Excluded (NEW)', value: PDPStatus.NEW },
  { label: 'Excluded (CALL)', value: PDPStatus.CALL },
];

export const memberExtractReportTypeOptions: SelectOption[] = [
  { label: 'Full scheme', value: ReportType.FullScheme },
  { label: 'Mi data (find and views)', value: ReportType.MiData },
  { label: 'Missing pension value', value: ReportType.MissingPensionValueData },
  { label: 'Matching results', value: ReportType.MatchingResults },
  { label: 'Membership extract (personal, pension and values)', value: ReportType.MembershipData },
  { label: 'Membership details extract for updating Admin platforms', value: ReportType.MembershipDetails },
];

export const altusSyncStatusOptions: SelectOption[] = [
  { label: 'Success', value: AltusSyncStatus.Processed },
  { label: 'Awaiting', value: AltusSyncStatus.PendingProcessing },
  { label: AltusSyncStatus.Failed, value: AltusSyncStatus.Failed },
  { label: AltusSyncStatus.Aborted, value: AltusSyncStatus.Aborted },
  { label: 'Not started', value: AltusSyncStatus.NotStarted },
];

export const environmentPublishStatusOptions: SelectOption[] = [
  { value: EnvironmentPublishStatus.Success, label: 'Published successfully' },
  { value: EnvironmentPublishStatus.Failed, label: 'Publish failed' },
];

export const additionalSchemeTypeOptions: SelectOption[] = [
  { value: AdditionalSchemeType.FOR, label: 'Former' },
  { value: AdditionalSchemeType.OTH, label: 'Other' },
];

export const booleanOptions: SelectOption[] = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
];

export const phoneRegExp = /^(?=.{1,20}$)(?:[+]?[\s0-9]*[(]{0,1}[0-9]{1,4}[)]{0,1})?[-\s0-9]*$/;
